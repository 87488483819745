$('body').on({
	'mousewheel': function(e) {
		if (e.target.id == 'el') return;
			e.preventDefault();
			e.stopPropagation();
	}
})

$('a[href^="#"]').on('click', function(event) {
    var target = $(this.getAttribute('href'));
    if( target.length ) {
        event.preventDefault();
        $('html, body').stop().animate({
            scrollTop: target.offset().top
        }, 1000);
    }
});


$(function () {
  var $win = $(window);
  var $pos = 100;
  $win.scroll(function () {
     if ($win.scrollTop() <= $pos)
      $('nav').removeClass('sticky');
     else {
       $('nav').addClass('sticky');
     }
   });
});